import React, { useContext, useState } from 'react';
import _ from "lodash";
import {AiFillMail} from 'react-icons/ai'

const Contact = props => {
    return <div className="Contact flex justify-center items-center flex-grow">

        <div className="Contact_Widget text-white text-3xl m-auto">

            <div className='Contact_Widget_Email' >
                <AiFillMail style={{display: 'inline'}}/> ruben@rubendegheselle.com
            </div>
        </div>


    </div>
}

export default Contact;