import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './theme/tailwind.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Projects from './components/Projects';
import Music from './components/Music';
import Contact from './components/Contact';
import ProjectDetails from './components/ProjectDetails';
import AppContent from './components/AppContent';
import Header from './components/Header';
import _ from "lodash";

import DataContext from './DataContext';
import client from './api';
import moment from "moment";
import 'tw-elements';


const App = () => {
  const { state, dispatch } = useContext(DataContext);
  useEffect(() => {
    client.getItems("bio", { fields: "*,image_1.data.full_url,image_2.data.full_url,image_3.data.full_url" }).then(response => {
      console.log(response);
      dispatch({
        type: `bio_get`,
        payload: response.data[0]
      })
    });

    client.getItems("projects", { fields: "*,image.data.full_url,banner_image.data.full_url" }).then(response => {
      console.log(response);
      dispatch({
        type: `projects_get`,
        payload:response.data.sort((a,b) => moment(b.date,  'YYYY-MM-DD').diff(moment(a.date,  'YYYY-MM-DD'))).map(el => {
          let date = moment(el.date, 'YYYY-MM-DD' )
            return {...el, formatted_year: date.format('YYYY')}
        })
      })
    });
    client.getItems("music").then(response => {
      console.log(response);
      dispatch({
        type: `music_get`,
        payload: response.data
      })
    });

  }, [])


  return (
    <div className="App">

        <Router>
          <Routes>
            <Route path="/" element={<AppContent dark={false}><Home /></AppContent>} />
   
            <Route path="/projects" element={<AppContent dark={true}><Projects /></AppContent>} />
            <Route path="/music" element={<AppContent dark={true}><Music /></AppContent>} />
            <Route path="/contact" element={<AppContent dark={true} full_height={true}><Contact /></AppContent>} />
            <Route path="/projects/:id" element={<AppContent dark={false} ><ProjectDetails /></AppContent>} />

          </Routes>
        </Router>


    </div>
  );
}

export default App;
