import React, { useContext } from 'react';
import { Figure } from 'react-bootstrap';
import DataContext from '../DataContext';

import htmlRender from "react-render-html";

const Music = props => {
    const { state, dispatch } = useContext(DataContext);
    const {  music } = state;


    const renderMusicSection = (project) => {
        return <div className="Music__Section p-8">
            <h1 className="text-white  text-3xl font-bold uppercase mb-4">{project.title}</h1>
            <div className="Music__Section__Listen">
                {htmlRender(project.embed)}
            </div>
        </div>
    }

    return <div className="Music lg:container lg:mx-auto ">


        {music.map(project => renderMusicSection(project))}
    </div>
}

export default Music;