import React, { useContext } from 'react';
import {
    // rest of the elements/components imported remain same
    useParams
} from 'react-router-dom';

import DataContext from '../DataContext';
import renderHTML from 'react-render-html';
import moment from "moment";

const SectionTitle = ({title}) => {

    return <h1 className="Section__Title">{title}</h1>
}
const ProjectDetails = () => {
    const { state, dispatch } = useContext(DataContext);
    let { id } = useParams();
    const project = state.projects.filter(proj => proj.id == id)[0]
    if (!project) {
        return <p></p>;
    }

    const bg_url = "https://images.pexels.com/photos/220067/pexels-photo-220067.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260";

    let intro_style;
    if (project.banner_image) {
        intro_style = {backgroundImage: `url(${project.banner_image.data.full_url})`};
    }
    return <div className="ProjectDetails container sm:mx-auto bg-white my-8  p-0">
        {project.banner_image && <div className="ProjectDetails__Intro bg-center	 bg-opacity-75 rounded-md bg-cover shadow-2xl shadow-inner		" style={intro_style}>
            <h1 className="object-left-bottom uppercase font-extrabold text-5xl subpixel-antialiased text-white p-3">{project.title}</h1>
        </div>}
        {!project.banner_image &&
            <h1 className="object-left-bottom uppercase font-extrabold text-5xl subpixel-antialiased p-3">{project.title}</h1>}
        <div className="p-4 px-16">
        {project.description && <div className="ProjectDetails__Content text-justify block my-10 text-xl">
            <SectionTitle title="Description" />

            {renderHTML(project.description)}
        </div>
        }
        {project.listen_codes && <div className="ProjectDetails__Listen text-justify block my-10 text-xl">
            <SectionTitle title="Listen" />

            {renderHTML(project.listen_codes)}
        </div>
        }
        {project.movie_codes && <div className="ProjectDetails__Movie text-justify block my-10 text-xl">
            <SectionTitle title="Watch" />
            {renderHTML(project.movie_codes)}
        </div>
        }
        <div className="ProjectDetails__Contributions mb-2 block">
            <SectionTitle title="Credits" />

            <table className="border-transparent table-fixed min-w-full my-2 ">

                <tbody>
                    
                    { project.director && <tr>
                        <td className="p-2">Director</td>
                        <td className="p-2 ">{project.director && renderHTML(project.director)}</td>
                    </tr>}
                    { project.musicians && <tr>
                        <td className="p-2">Musicians</td>
                        <td className="p-2">{project.musicians && renderHTML(project.musicians)}</td>
                    </tr>}
                    { project.production && <tr>
                        <td className="p-2 ">Production</td>
                        <td className="p-2">{project.production && renderHTML(project.production)}</td>
                    </tr>}
                    { project.music_mix &&<tr>
                        <td className="p-2">Music mix</td>
                        <td className="p-2">{project.music_mix && renderHTML(project.music_mix)}</td>
                    </tr>}
                    { project.other_info &&<tr>
                        <td className="p-2">Other info</td>
                        <td className="p-2">{project.other_info && renderHTML(project.other_info)}</td>
                    </tr>}
                    { project.date && <tr>
                        <td className="p-2">Production date</td>
                        <td className="p-2">{project.date && moment(project.date).format("DD/MM/YYYY")}</td>
                    </tr>}
                </tbody>
            </table>
        </div>
        </div>
    </div>
}

export default ProjectDetails;