import React, { useContext, useState } from 'react';
import DataContext from '../DataContext';
import { Link } from 'react-router-dom';
import moment from "moment";
import htmlRender from "react-render-html";
import _ from "lodash";


const groupBy = key => array =>
    array.reduce(
        (objectsByKeyValue, obj) => ({
            ...objectsByKeyValue,
            [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
        }),
        {}
    );


const Projects = props => {
    const { state, dispatch } = useContext(DataContext);
    const { projects } = state;

    const [activeFilter, setActiveFilter] = useState("ALL");

    const renderProjectCard = (project) => {
        return <Link to={`/projects/${project.id}`}><div className="ProjectCard text-white group w-full h-full">
            <div className="ProjectCard__Image shadow-inner w-full overflow-hidden">
                <img src={project.image.data.full_url} className="w-full transform transition duration-500 group-hover:scale-125" />

            </div>
            <div className="ProjectCard__Meta flex flex-wrap py-2 transform transition group-hover:translate-x-2">


                <div className="flex-grow">
                    <div className="ProjectCard__Title">
                        {project.title}
                    </div>
                    <div className="ProjectCard__Type">
                        {project.type}
                    </div>
                </div>

            </div>
        </div>
        </Link>
    }

    const onFilterClick = (event, tag_name) => {
        setActiveFilter(tag_name)
    }

    const renderFilterTag = (tag_name) => {
        if (tag_name == activeFilter) {
            return <button className="active bg-red-600 text-gray-50 uppercase p-1.5 rounded-sm ring-2 ring-red-600 m-2" onClick={e => onFilterClick(e, tag_name)}>{tag_name}</button>

        } else {
            return <button className="active text-gray-50 uppercase p-1.5 rounded-sm m-2" onClick={e => onFilterClick(e, tag_name)}>{tag_name}</button>
        }
    }
    const unique_types = projects.map(project => {
        return project.type ? project.type.toUpperCase() : "";
    }).filter(el => el != "").filter((value, index, self) => self.indexOf(value) === index);

    const groupByYear = groupBy('formatted_year');

    const grouped_per_year = groupByYear(projects)

    return <div className="Projects ray-0 lg:container lg:mx-auto">

        {projects.length > 0 && unique_types && <div className="Projects__Filter py-8 flex justify-center">

            {renderFilterTag("ALL")}

            {unique_types.map(el => {
                return renderFilterTag(el);
            })}
        </div>}

        {
            !_.isEmpty(grouped_per_year) && Object.entries(grouped_per_year).sort(el => -(parseInt(el[0]))).map(el => {
                let year = el[0];
                let projects = el[1].filter(proj => {
                    if (activeFilter != "ALL") {
                        return proj.type && proj.type.toUpperCase() == activeFilter;
                    } else {
                        return proj
                    }
                })
                return <div className="Projects_For_Year">
                    {projects.length > 0 && <div className="Projects_For_Year_YearLabel">
                        <span class="text-white p-2 rounded-r-lg text-lg	">
                            {year}
                        </span>
                    </div>}
                    {projects.length > 0 && <div className="Projects__Grid grid lg:grid-cols-4 gap-5 md:grid-cols-2 p-8">
                        {projects.map(project => {
                            return renderProjectCard(project);
                        })}
                    </div>}
                </div>
                console.log(el);
            })
        }



    </div>
}

export default Projects;