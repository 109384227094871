import React, {useReducer} from 'react';

import DataContext from "./DataContext";
import dataReducer from "./dataReducer";

const { Provider } = DataContext;
const initialState = {
    bio: {},
    projects: [],
    music: [],
}

const StateProvider = ( { children } ) => {
    
    const [state, dispatch] = useReducer(dataReducer, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { DataContext, StateProvider };