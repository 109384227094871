import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = props => {
    return <Navbar  bg="dark" variant="dark" expand="lg" className="Header" sticky="top">
    <Navbar.Brand className="Header__Brand" href="/">Ruben De Gheselle</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/projects">Projects</Nav.Link>
        <Nav.Link href="/music">Music</Nav.Link>
        <Nav.Link href="/contact">Contact</Nav.Link>

      </Nav>
    </Navbar.Collapse>
  </Navbar>
};

export default Header;
