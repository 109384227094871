import React from 'react';
import App from './App';
import {StateProvider} from "./StateProvider";


function AppWrapper() {
    return (
        <StateProvider>
            
            <App/>
        </StateProvider>
    );
}

export default AppWrapper;
