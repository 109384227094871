const dataReducer = (state, action) => {
    switch (action.type) {
        case 'bio_get': {
            return { ...state, bio: action.payload}
        }

        case 'projects_get': {
            return { ...state, projects: action.payload}
        }

        case 'music_get': {
            return { ...state, music: action.payload}
        }
       
       
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

export default dataReducer;
